import { requestToken } from '@/utils/request.js'

// 获取图片列表
export const getImagesList = (data) => {
  return requestToken('/station/imagesList', data)
}

// 上传图片
export const uploadImages = (data) => {
  return requestToken('/station/uploadImages', data)
}

// 删除图片
export const delImages = (data) => {
  return requestToken('/station/deleteImage', data)
}
