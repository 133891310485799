import service from '../utils/request'
import { getToken } from "../utils/auth";
// 上传接口 /file/uploadFile
export const upLoadFile = (data) => {
    const dealData = new FormData()
    dealData.append("token", getToken());
    for (let key in data) {
        dealData.append(key, data[key])
    }
    return service({
        data: dealData,
        method: 'post',
        url: '/file/uploadFile',
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}