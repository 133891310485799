<template>
  <div class="flex-content">
    <div class="flex-alignc-justifyb bgfff pd-20 header-title">
      <div>照片</div>
      <a-button type="primary" @click="openTopDrawer">添加新照片</a-button>
    </div>
    <div class="flex-main">
      <div class="photo-list">
        <div class="bgfff item pointer" v-for="(item,index) in imgList" :key="index">
          <img :src="item.fileUrl" alt="" @click="handlePreview(item.fileUrl)">
          <div>{{ item.title }}</div>
          <a-icon class="close-icon" type="close-circle" @click="handleDelImg(item)" />
        </div>
      </div>
    </div>

    <!-- 添加照片弹窗 -->
    <a-drawer :bodyStyle="{
        padding: '0.3rem 0 0 0',
      }" :closable="false" :destroyOnClose="true" :headerStyle="{
        background: '#f0f1f5',
        padding: '0 0.2rem 0 0.3rem',
      }" :visible="visible" @close="closeTopDrawer" height="100%" placement="top" wrapClassName="add-power">
      <template #title>
        <div class="head-box">
          <span>添加新照片</span>
          <div class="head-btn">
            <a-button @click="closeTopDrawer">取消</a-button>
            <a-button class="save-btn" type="primary" @click="handleSubmit">保存</a-button>
          </div>
        </div>
      </template>
      <div class="content-box scroll-y">
        <div class="h100 scroll-y">
          <a-form-model style="width:50%;margin:auto;" ref="form" :model="form" :rules="rules">
            <a-form-model-item label="标题" prop="title">
              <a-input v-model="form.title"></a-input>
            </a-form-model-item>
            <a-form-model-item label="图片" prop="fileEntities">
                <a-upload
                  list-type="picture-card"
                  :file-list="form.fileEntities"
                  :before-upload="uploadCheck"
                  :customRequest="ownUpload"
                  @change="handleChange"
                >
                  <div v-if="!form.fileEntities.length">
                    <a-icon type="plus" />
                  </div>
                </a-upload>
              </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-drawer>

    <!-- 图片预览 -->
    <a-modal :visible="previewShow" :footer="null" @cancel="cancel">
      <img alt="example" style="width: 100%" :src="previewSrc" />
    </a-modal>
  </div>
</template>

<script>
import { getImagesList,uploadImages,delImages } from "@/api/station/photo";
import { upLoadFile } from "@/api/publicApi";
export default {
  data () {
    return {
      imgList:[],
      visible: false,
      form: {
        id:'',
        title: '',
        fileEntities:[]
      },
      rules: {
        title: [{
          required: true,
          message: '请填写标题',
          trigger: 'suiyidingyi'
        }],
        fileEntities: [
          { required: true,trigger: 'blur',validator: (rule, value, callback) => {
              if(!value.length) {
                callback('请上传图片')
              }else{
                callback()
              }
            }
          }
        ]
      },
      previewShow:false,
      previewSrc:''
    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.getImagesList()
  },
  methods: {
    // 预览图片
    handlePreview(url) {
      this.previewSrc = url
      this.previewShow = true
    },
    cancel() {
      this.previewShow = false
    },
     // 删除图片
     handleDelImg(item) {
      let that = this
      this.$confirm({
        title: '确认删除该图片吗?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          delImages({
            id:item.id
          }).then(res => {
            that.getImagesList()
          })
        }
      });
    },
    // 获取图片列表
    async getImagesList() {
      try {
        let res = await getImagesList({id:this.form.id})
        this.imgList = res.data
      }catch(err) {

      }
    },
    openTopDrawer () {
      this.visible = true
    },
    closeTopDrawer () {
      this.visible = false
    },
    uploadCheck(file, fileList) {
      const imgType = ["image/gif", "image/jpeg", "image/pjpeg", "image/png"];
      if (imgType.includes(file.type)) {
        return true;
      } else {
        this.$message.warning("请上传jpeg、png、gif类型的图片");
        return false;
      }
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        return;
      }
      if (info.file.status === "removed") {
        let index = this.form.fileEntities.findIndex(item => {return item.uid==info.file.uid})
        if(index!=-1) {
          this.form.fileEntities.splice(index,1)
        }
      }
    },
    ownUpload(options) {
      const formdata = {
        multipartFiles: options.file,
      };
      upLoadFile(formdata).then((res) => {
        res.data.forEach((item,index) => {
          item.name = item.fileUrl
          item.uid = '-'+index
          item.url = item.fileUrl
          item.status = 'done'
        })
        this.form.fileEntities = res.data
      });
    },
    // 添加照片
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.fileEntities[0].title = this.form.title
          uploadImages(this.form).then(res => {
            this.getImagesList()
            this.closeTopDrawer()
          })
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.header-title {
  font-weight: bold;
  font-size: 28px;
}
.photo-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    position: relative;
    border-radius: 5px;
    margin-right: 30px;
    img {
      width: 200px;
      height: 200px;
      border-radius: 5px;
    }
    div {
      text-align: center;
      padding: 10px 0;
    }
    .close-icon {
      position: absolute;
      top: -15px;
      right: -15px;
      font-size: 30px;
      z-index: 10;
    }
  }
}
.add-power {
  .head-box {
    width: 100%;
    height: 64px;
    font-size: 20px;
    color: #252b3a;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-btn {
      /deep/.ant-btn {
        font-size: 12px;
      }

      .save-btn {
        margin-left: 8px;
      }
    }
  }

  /deep/.ant-drawer-body {
    height: calc(100vh - 100px);
  }

  .content-box {
    width: 100%;
    height: 100%;
    padding-top: 24px;
    .report-textarea {
      resize: none;
      background-color: transparent !important;
    }
    .left {
      padding-right: 10px;

      /deep/.ant-form label {
        font-size: 12px;
        color: #00000073;
      }

      /deep/.ant-form-item-label {
        line-height: initial !important;
      }

      /deep/.ant-form-item {
        margin-bottom: 12px;
      }

      .tagBox-wrap {
        position: relative;
        border: 1px solid #d9d9d9;
        line-height: initial;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
      }
    }

    .flow {
      padding-left: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);

      li {
        position: relative;
        padding: 0 40px 60px 40px;

        &:before {
          position: absolute;
          display: block;
          content: "";
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          background-color: hsla(0, 0%, 50.2%, 0.6);
          border-radius: 50%;
        }

        &:after {
          position: absolute;
          display: block;
          content: "";
          top: 8px;
          bottom: -3px;
          left: 7px;
          width: 1px;
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 1px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
        .add-member {
          position: absolute;
          top: 0;
          right: 0;
        }
        .tip {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }

        .header {
          position: relative;
          margin-bottom: 10px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }

          .close {
            position: absolute;
            top: -20%;
            right: -20%;
          }
        }
      }
    }
  }
}
</style>